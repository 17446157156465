
import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue'
import Sticky from '@/components/Sticky.vue'

@Component({
  components: {
    Page,
    Sticky,
  }
})
export default class AboutView extends Vue {

}
