import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ServicesView from '@/views/ServicesView.vue';
import PortfolioView from '../views/PortfolioView.vue'
import TestimonialsView from '@/views/TestimonialsView.vue';
import AboutView from '../views/AboutView.vue'
import ResumeView from '../views/ResumeView.vue'
import FAQView from '@/views/FAQView.vue';
import ContactView from '../views/ContactView.vue'
import ErrorView from '../views/ErrorView.vue'
import { portfolioItems } from '@/global';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/services', name: 'services', component: ServicesView },
  { path: '/portfolio/:project?', name: 'portfolio', component: PortfolioView, beforeEnter: (to: Route, from: Route, next: NavigationGuardNext<Vue<Record<string, any>>>) => {
    if (!to.params.project) {
      return next({ name: 'portfolio', params: { project: portfolioItems[0].key }});
    }
    if (portfolioItems.find(portfolioItem => portfolioItem.key === to.params.project)) {
      return next();
    }
    next({ name: 'error', params: { code: '404' } });
  } },
  { path: '/testimonials', name: 'testimonials', component: TestimonialsView },
  { path: '/about', name: 'about', component: AboutView },
  { path: '/resume', name: 'resume', component: ResumeView },
  { path: '/faq', name: 'faq', component: FAQView },
  { path: '/contact', name: 'contact', component: ContactView },
  { path: '/error/:code?', name: 'error', component: ErrorView, meta: { dark: true } },
  { path: '*', name: 'catchError', redirect: { name: 'error', params: { code: '404' } } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
