import Vue from 'vue';
import VueI18n, { I18nOptions } from 'vue-i18n'
import enCA from '@/locales/en-CA.json';
import frCA from '@/locales/fr-CA.json';
import esMX from '@/locales/es-MX.json';

Vue.use(VueI18n);

const messages: any = {
  'en-CA': enCA,
  'fr-CA': frCA,
  'es-MX': esMX,
};

let locale = localStorage.getItem('locale');
if (!locale) {
  const navLang = navigator.language;
  const availableLanguages = Object.keys(messages).map(lang => lang.substring(0, 2));
  const index = availableLanguages.indexOf(navLang);
  if (index === -1) {
    locale = 'en-CA';
  } else {
    locale = Object.keys(messages)[index];
  }
} else if (Object.keys(messages).indexOf(locale) === -1) {
  locale = 'en-CA';
}

const opts: I18nOptions = {
  locale,
  fallbackLocale: ['en-CA'],
  formatFallbackMessages: true,
  messages,
};

export default new VueI18n(opts);
