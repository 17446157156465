
import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue'

@Component({
  components: {
    Page,
  }
})
export default class HomeView extends Vue {

  get video(): string {
    return this.$vuetify.breakpoint.mdAndUp
      ? '/videos/coding.mp4'
      : '/videos/coding.mp4';
  }
}
