const portfolioItems = [
  { key: 'raysecur', is: 'Laptop', maxWidth: '100%', src: '/images/portfolio/ray-secur.gif', tech: 'Vue, Vuetify, Typescript, Google Spreadsheet', position: 'center bottom' },
  { key: 'happybox', is: 'Laptop', maxWidth: '100%', src: '/images/portfolio/happybox.jpg', tech: 'PHP, MySQL, JavaScript, jQuery, AWS', position: 'left center' },
  // { key: 'polymind', is: 'IPhone', maxWidth: '215px', src: '/images/portfolio/polymind-app.jpg', tech: 'Laravel, VueJS, Vuetify, React Native, TypeScript, MySQL, AWS' },
  { key: 'polymind', is: 'Laptop', maxWidth: '100%', video: '/videos/portfolio/polymind.mkv', tech: 'Laravel, VueJS, Vuetify, React Native, TypeScript, MySQL, AWS', backgroundColor: '#272727' },
  { key: 'fungal-fury', is: 'Vintage', maxWidth: '100%', src: '/images/portfolio/fungal-fury.png', tech: 'Assembly 6502, CA65 compiler, Mesen' },
  { key: 'ray-caster', is: 'Laptop', maxWidth: '100%', src: '/images/portfolio/ray-caster.gif', tech: 'C++, CMake', position: 'center bottom' },
  { key: 'nft', is: 'Laptop', maxWidth: '100%', video: '/videos/portfolio/nft-pirate.mkv', tech: 'Blender, Python, PHP, MySQL, Heroku, Cardano, Bash', position: 'center center', backgroundColor: '#ffc95d' },
]

const links: Array<{
  key: string,
  icon: string | null,
  section: 'primary' | 'secondary',
  params?: {[key: string]: string}
}> = [
  { key: 'home', icon: 'mdi-presentation', section: 'primary' },
  { key: 'services', icon: 'mdi-room-service-outline', section: 'primary' },
  { key: 'portfolio', icon: 'mdi-view-carousel-outline', section: 'primary', params: { project: portfolioItems[0].key } },
  { key: 'testimonials', icon: 'mdi-comment-check-outline', section: 'primary' },
  // { key: 'about', icon: 'mdi-information-outline', section: 'secondary' },
  { key: 'resume', icon: 'mdi-account-tie-outline', section: 'secondary' },
  { key: 'faq', icon: 'mdi-frequently-asked-questions', section: 'secondary' },
  { key: 'contact', icon: 'mdi-email-outline', section: 'primary' },
]

const socialLinks: Array<{
  href: string,
  icon: string,
  name: string,
}> = [
  { href: 'https://www.linkedin.com/in/dannycoulombe/', icon: 'mdi-linkedin', name: 'LinkedIn' },
]

export {
  links,
  socialLinks,
  portfolioItems,
}
