
import { Component, Vue } from 'vue-property-decorator'
import Sticky from '@/components/Sticky.vue'
import Page from '@/components/Page.vue'

@Component({
  components: {
    Sticky,
    Page,
  }
})
export default class FAQView extends Vue {
  items = [
    { key: 'location', icon: 'mdi-map-marker' },
    { key: 'expertise', icon: 'mdi-code-tags' },
    { key: 'work', icon: 'mdi-account-tie' },
    { key: 'incorporation', icon: 'mdi-office-building' },
    { key: 'project', icon: 'mdi-signature-freehand' },
    { key: 'existing', icon: 'mdi-help-rhombus-outline' },
    { key: 'onSite', icon: 'mdi-car' },
    { key: 'communication', icon: 'mdi-message-text' },
    { key: 'availability', icon: 'mdi-account-clock' },
    { key: 'rates', icon: 'mdi-cash-usd-outline' },
    { key: 'maintenance', icon: 'mdi-hammer' },
    { key: 'mentoring', icon: 'mdi-teach' },
  ];
}
