import Vue from 'vue'
import * as marked from 'marked'

Vue.filter('markdown', function (str: string) {
  return marked.parse(str);
})

Vue.filter('excerpt', function (str: string, limit = -1) {
  if (str.length <= limit || limit === -1) {
    return str;
  }
  return str.substring(0, limit === -1 ? 99999999999999 : limit) + '...';
})
