
import { Component, Vue, Watch } from 'vue-property-decorator';
import Sticky from '@/components/Sticky.vue';
import Page from '@/components/Page.vue'
import Laptop from '@/components/Laptop.vue'
import IPhone from '@/components/IPhone.vue'
import Vintage from '@/components/Vintage.vue'
import { Route } from 'vue-router';
import { portfolioItems } from '@/global';

let isMounted = false;

@Component({
  components: {
    Sticky,
    Page,
    Laptop,
    IPhone,
    Vintage,
  }
})
export default class PortfolioView extends Vue {

  isMounted = false
  videoLoaded = false
  lastComponent: string | null = null
  items = portfolioItems

  @Watch('$route.fullPath', {
    immediate: true,
  })
  onCurrentItemIdxChange(newIdx: number, oldIdx: number) {
    this.lastComponent = this.items[oldIdx]?.is;

    this.$vuetify.goTo('html', {
      duration: 0
    });
    this.videoLoaded = false;
    setTimeout(() => this.videoLoaded = true, this.isMounted ? 0 : 100);
  }

  get lastComponentDifferent(): boolean {
    return this.lastComponent !== this.currentItem.is;
  }

  get currentItem(): any {
    return this.items[this.currentItemIndex];
  }

  get currentItemIndex(): any {
    return this.items.findIndex(item => item.key === this.$route.params.project);
  }

  get canPlayVideo(): boolean {
    return this.videoLoaded && process.env.NODE_ENV !== 'development';
  }

  get prevItem(): any {
    const currentIdx = this.currentItemIndex;
    if (currentIdx === 0) {
      return this.items[this.items.length - 1];
    }
    return this.items[currentIdx - 1];
  }

  get nextItem(): any {
    const currentIdx = this.currentItemIndex;
    if (currentIdx === this.items.length - 1) {
      return this.items[0];
    }
    return this.items[currentIdx + 1];
  }

  onKeyLeft() {
    const currentIdx = portfolioItems.findIndex(item => item.key === this.$route.params.project);
    const newIdx = currentIdx === 0 ? portfolioItems.length - 1 : currentIdx - 1;
    this.$router.push({ name: 'portfolio', params: { project: portfolioItems[newIdx].key }})
  }

  onKeyRight() {
    const currentIdx = portfolioItems.findIndex(item => item.key === this.$route.params.project);
    const newIdx = currentIdx === portfolioItems.length - 1 ? 0 : currentIdx + 1;
    this.$router.push({ name: 'portfolio', params: { project: portfolioItems[newIdx].key }})
  }

  onKeyDown(e: any) {
    if (e.key === 'ArrowLeft') {
      this.onKeyLeft();
    } else if (e.key === 'ArrowRight') {
      this.onKeyRight();
    }
  }

  mounted() {
    isMounted = true;
  }

  created() {
    if (!this.$route.params.project) {
      this.$router.replace({ name: 'portfolio', params: { project: this.items[0].key } });
    }
    this.lastComponent = this.currentItem.is;
    this.isMounted = isMounted;

    document.addEventListener('keydown', this.onKeyDown);
  }

  destroyed() {
    document.removeEventListener('keydown', this.onKeyDown);
  }
}
