
import { Component, Vue } from 'vue-property-decorator';
import Sticky from '@/components/Sticky.vue';
import Page from '@/components/Page.vue'

interface ITestimonial {
  expand: boolean,
  note: number,
  name: string,
  picture: string,
  comment: string,
  company: string | null,
}

@Component({
  components: {
    Sticky,
    Page,
  }
})
export default class TestimonialsView extends Vue {

  loaded = true
  items: Array<ITestimonial> = [
    { expand: false, picture: '/images/testimonials/gene.jpeg', note: 5, name: 'Gene Khalyapin', comment: 'Danny is very professional and he knows his stuff really well. He quickly identified the root problem with our web-site and fixed it faster than I had ever expected. Highly recommended!', company: 'GXB.ca' },
    { expand: false, picture: '/images/testimonials/francois.jpeg', note: 5, name: 'Francois-Xavier Harbec', comment: 'Danny est la personne la plus compétente que je connaisse en informatique. Il performe de façon audacieuse sur ses projet et il est déterminé dans ce qu\'il souhaite accomplir. Je recommande vivement Danny pour son savoir-faire et son ardeur au travail.', company: 'Carrefour jeunesse emploi Ahuntsic Bordeaux-Cartierville' },
    { expand: false, picture: '/images/testimonials/alexandre.jpeg', note: 5, name: 'Alexandre Le Moëligou', comment: 'Danny est extrêmement qualifié dans le domaine du développement web. J\'ai eu la chance de travailler avec lui à plusieurs reprises et chaque fois que j\'ai été vraiment impressionné par les résultats fournis. Merci beaucoup pour ton travail très professionnel et je n\'hésiterais pas à te recommander à qui que ce soit!', company: 'Folks Interactive Inc.' },
    { expand: false, picture: '/images/testimonials/guillaume.jpeg', note: 5, name: 'Guillaume Lagacé', comment: 'Danny possède des compétences inégalées dans le domaine de l\'intégration et de la programmation web. Son professionnalisme et sa rigueur vous permettrons de réaliser des projets de grande envergure et d\'en assurer le succès. Dans le domaine du web, il est le secret le mieux gardé de Montréal!', company: 'WebRight' },
    { expand: false, picture: '/images/testimonials/francois-lacroix.jpeg', note: 5, name: 'Francois Lacroix-Durant', comment: 'Danny is a great lead developer, he clearly explains what need to be done, he gave you good hints on how and where to begin. Learning under his wing was a great pleasure.', company: 'Sword Group' },
    { expand: false, picture: '/images/testimonials/sebastien.jpeg', note: 5, name: 'Sébastien Girard', comment: 'M. Coulombe est une personne intègre et professionnelle. Il a intégrer plusieurs de mes sites en peu de temps et cela a pu me permettre d\'être prêt au niveau de mon organisation à développer de nouveau marché.', company: 'Productions Sébastien Girard' },
  ]
  limit = 300
  clients = [
    'montreal',
    'quebec',
    'mcgill',
    'yolarx',
  ]

  get reversedTestimonials(): Array<ITestimonial> {
    return this.items.reverse();
  }

  onShowMoreLess(testimonial: ITestimonial): void {
    testimonial.expand = !testimonial.expand;
    this.$nextTick(this.redrawMasonry);
  }

  redrawMasonry(): void {
    // this.$redrawVueMasonry('testimonials');
  }
}
