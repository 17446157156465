var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Page',{attrs:{"color":"background","force-flat-toolbar-offset":96}},[_c('v-container',{staticClass:"h-100 pt-6 pb-3 py-md-12 pt-lg-3 container-md",attrs:{"id":"calendar"}},[_c('h1',{directives:[{name:"show-timeout",rawName:"v-show-timeout",value:([0, 'slide-y']),expression:"[0, 'slide-y']"}],staticClass:"text-h4 text-md-h3 mb-6"},[_vm._v(_vm._s(_vm.$t('testimonials.title')))]),_c('p',{directives:[{name:"show-timeout",rawName:"v-show-timeout",value:([200, 'slide-y']),expression:"[200, 'slide-y']"}],staticClass:"body-1 mb-12",domProps:{"innerHTML":_vm._s(_vm.$options.filters?.markdown(_vm.$t('testimonials.body')))}}),_c('h3',{directives:[{name:"show-timeout",rawName:"v-show-timeout",value:([300, 'slide-x-reverse']),expression:"[300, 'slide-x-reverse']"}],domProps:{"textContent":_vm._s(_vm.$t('testimonials.clients.title'))}}),_c('v-container',{staticClass:"px-0"},[_c('v-row',{attrs:{"dense":_vm.$vuetify.breakpoint.smAndDown}},_vm._l((_vm.clients),function(client,clientIdx){return _c('v-col',{directives:[{name:"show-timeout",rawName:"v-show-timeout",value:([300 + (clientIdx * 50), 'scale']),expression:"[300 + (clientIdx * 50), 'scale']"}],key:client,attrs:{"md":12 / _vm.clients.length,"cols":"12"}},[_c('v-card',{staticClass:"w-100 h-100 d-flex align-center",attrs:{"light":"","outlined":""}},[_c('v-card-text',[_c('v-img',{staticClass:"ma-auto",style:({
                  maxWidth: _vm.$vuetify.breakpoint.lgAndUp ? '150px' : null,
                  maxHeight: _vm.$vuetify.breakpoint.lgAndUp ? '75px' : null,
                  height: _vm.$vuetify.breakpoint.lgAndUp ? null : '40px',
                }),attrs:{"src":'/images/clients/' + client + '.png',"alt":_vm.$t('testimonials.clients.items.' + client).toString(),"contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-overlay',{attrs:{"absolute":""}},[_c('v-progress-circular',{attrs:{"size":"32","indeterminate":""}})],1)]},proxy:true}],null,true)})],1)],1)],1)}),1)],1),_c('div',{staticClass:"d-flex align-center justify-space-between mt-12 mb-3"},[(_vm.loaded)?_c('h3',{directives:[{name:"show-timeout",rawName:"v-show-timeout",value:([400, 'slide-x-reverse']),expression:"[400, 'slide-x-reverse']"}],domProps:{"textContent":_vm._s(_vm.$t('testimonials.comments', {
        amount: _vm.items.length
      }))}}):_vm._e()]),(!_vm.loaded)?_c('v-progress-linear',{staticClass:"mt-12",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-row',{directives:[{name:"masonry",rawName:"v-masonry",value:('testimonials'),expression:"'testimonials'"}],attrs:{"transition-duration":"0.3s"}},_vm._l((_vm.reversedTestimonials),function(testimonial,testimonialIdx){return _c('v-col',{key:testimonialIdx,attrs:{"cols":"12","md":"4"}},[_c('v-card',{directives:[{name:"show-timeout",rawName:"v-show-timeout",value:([(50 * testimonialIdx) + 400, 'slide-y']),expression:"[(50 * testimonialIdx) + 400, 'slide-y']"}],attrs:{"light":""}},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"48px"}},[_c('v-avatar',{staticStyle:{"margin-top":"-48px"},attrs:{"size":"96"}},[_c('v-img',{attrs:{"src":testimonial.picture},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-overlay',{attrs:{"absolute":""}},[_c('v-progress-circular',{attrs:{"color":"primary","size":"64","indeterminate":""}})],1)]},proxy:true}],null,true)})],1)],1),_c('v-card-title',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(testimonial.name))]),(testimonial.company)?_c('v-card-subtitle',{staticStyle:{"text-transform":"capitalize"}},[_c('span',[_vm._v(_vm._s(testimonial.company))])]):_vm._e(),_c('v-card-text',{staticClass:"py-0 mt-n4 mx-n1"},[_c('v-rating',{attrs:{"color":"warning","background-color":"warning","half-increments":"","dense":"","readonly":""},model:{value:(testimonial.note),callback:function ($$v) {_vm.$set(testimonial, "note", $$v)},expression:"testimonial.note"}})],1),_c('v-card-text',[_vm._v(" "+_vm._s(testimonial.comment)+" ")])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }