
import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue'
import Sticky from '@/components/Sticky.vue'
import jsPDF from 'jspdf';
import moment from 'moment';
import '@/assets/fonts/Anton-Regular-normal'
import '@/assets/fonts/geomanist-regular-webfont-normal'

const month = (new Date().getMonth() + 1)
const now = new Date().getFullYear() + '-' + (month < 10 ? '0' + month : month);

@Component({
  components: {
    Page,
    Sticky,
  }
})
export default class ResumeView extends Vue {

  tools = [
    { text: 'VueJS', logo: './images/technologies/vuejs.png' },
    { text: 'React', logo: './images/technologies/react.png' },
    // { text: 'Vuetify', logo: './images/technologies/vuetify.png' },
    { text: 'Laravel', logo: './images/technologies/laravel.png' },
  ]

  languages = [
    { key: 'fr', rating: 'native' },
    { key: 'en', rating: 'bilingual' },
    { key: 'es', rating: 'fullPro' },
  ]

  jobs = [
    { key: 'yolarx', positionType: 'permanentFullTime', from: '2022-11', to: now, location: 'Montreal, Canada', logo: './images/resume/yolarx.png', technologies: 'VueJS, Vuetify, Typescript, JsPDF, PHP (Phalcon), MySQL, Cypress, Jest' },
    { key: 'connectngo', positionType: 'contractFullTime', from: '2021-03', to: '2022-11', location: 'Montreal, Canada', logo: './images/resume/connectngo.png', technologies: 'VueJS, Vuetify, Typescript, Laravel, PHP, Docker' },
    { key: 'polymind', positionType: 'selfEmployed', from: '2019-03', to: '2021-03', location: 'Montreal, Canada', logo: './images/resume/polymind.png', technologies: 'VueJS, Vuetify, Typescript, React Native, Laravel, MySQL, AWS, Docker' },
    { key: 'fon', positionType: 'contractFullTime', from: '2015-02', to: '2019-03', location: 'Montreal, Canada', logo: './images/resume/fon.png', technologies: 'VueJS, AngularJS, TypeScript, Python, Cordova, Grunt, Bootstrap, HTML' },
    { key: 'happybox', positionType: 'selfEmployed', from: '2011-08', to: '2015-02', location: 'Montreal, Canada', logo: './images/resume/happybox.png', technologies: 'JavaScript, PHP, MySQL, HTML5, CSS3' },
    { key: 'leonarddg', positionType: 'permanentFullTime', from: '2010-02', to: '2011-08', location: 'Quebec, Canada', logo: './images/resume/leonarddg.png', technologies: 'PHP, ASP.NET, SQL Server, HTML, CSS' },
    { key: 'multihexa', positionType: 'permanentFullTime', from: '2008-11', to: '2010-02', location: 'Quebec, Canada', logo: './images/resume/multihexa.png', technologies: 'JavaScript, SQL Server, HTML, CSS' },
  ]

  get sticky(): boolean {
    return window.innerHeight > 700 && this.$vuetify.breakpoint.mdAndUp;
  }

  get totalMonthsOfExperience(): number {
    let months = 0;
    this.jobs.forEach(job => {
      months += this.getDurationInMonths(job.from, job.to);
    })
    return months;
  }

  get totalYearsOfExperience(): number {
    return Math.floor(this.totalMonthsOfExperience / 12);
  }

  get totalExperience(): string {
    const totalMonths = this.totalMonthsOfExperience;
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths - (years * 12);
    return (
        (years > 0 ? years + ' ' + this.$i18n.tc('resume.years', years) : '')
        + (months > 0 ? ' ' + months + ' ' + this.$i18n.tc('resume.months', months) : '')
      ).trim()
  }

  getDuration(from: string, to: string) {
    const totalMonths = this.getDurationInMonths(from, to);
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths - (years * 12);
    return (
      (years > 0 ? years + ' ' + this.$i18n.tc('resume.years', years) : '')
      + (months > 0 ? ' ' + months + ' ' + this.$i18n.tc('resume.months', months) : '')
    ).trim()
  }

  getDurationInMonths(from: string, to: string) {
    return moment(to).diff(moment(from), "month");
  }

  onPrintClick() {
    window.print();
  }

  downloadAsPDF() {
    const doc = new jsPDF();
    const initialY = 20;
    const pageHeight = 280;
    let startY = initialY;

    function drawLine() {
      if (startY < pageHeight) {
        doc.setLineWidth(0.2);
        doc.setDrawColor(224, 224, 224);
        doc.line(14, startY, 192, startY);
        startY += 10;
      }
    }

    function checkStartY() {
      if (startY >= pageHeight) {
        doc.addPage();
        startY = initialY;
      }
    }

    function printImage(path: string, x: number, y: number, w: number, h: number, updateStartY = true) {
      checkStartY();

      const img = new Image();
      img.src = path;
      doc.addImage(img, 'png', x, y, w, h);

      if (updateStartY) {
        startY += h;
      }
    }

    function printText(str: string, size = 12, style = 'normal', color = '#000000', font = 'Verdana', x = 14) {
      checkStartY();

      doc.setFontSize(size);
      doc.setFont(font, style);
      doc.setTextColor(color);

      const splitTitle = doc.splitTextToSize(str, 192 - x);
      doc.text(splitTitle, x, startY);
      startY += ((size * splitTitle.length) / 2.5);
    }

    doc.setFillColor(48, 48,48);
    doc.rect(0, 0, 210, 40, 'F');
    doc.setFillColor(220, 229,255);
    doc.rect(0, 40, 210, 2, 'F');

    printImage('/images/resume-picture.png', 14, startY, 40, 40, false);
    startY += 7.5;
    printText('Danny Coulombe', 24, 'normal', '#FFFFFF', 'Anton-Regular', 65);
    startY -= 2;
    printText(this.$i18n.t('resume.subtitle').toString(), 14, 'normal', '#dce5f4', 'geomanist-regular-webfont', 65);
    startY += 10.5;

    // printText(this.$i18n.t('resume.about.title').toString(), 11, 'normal', undefined, undefined, 65);
    printText('+1 (514) 427-2525', 11, 'normal', undefined, undefined, 65);
    printText('danny@coulombe.dev', 11, 'normal', undefined, undefined, 65);
    printText('https://www.coulombe.dev', 11, 'normal', '#007BFF', undefined, 65);
    startY += 10;

    printText(this.$i18n.t('resume.about.title').toString(), 18, 'normal', undefined, 'geomanist-regular-webfont');
    printText(this.$i18n.t('resume.about.body', { years: this.totalYearsOfExperience }).toString(), 11, 'normal');
    startY += 10;

    printText(this.$i18n.t('resume.experiences').toString(), 18, 'normal', undefined, 'geomanist-regular-webfont');
    startY += 5;

    this.jobs.forEach((job, jobIdx) => {
      if (jobIdx > 0) {
        startY += 3;
        drawLine();
        startY += 1;
      }

      checkStartY();
      printImage('/images/resume/' + job.key + '.png', 14, startY - 4, 5, 5, false);
      printText(this.$i18n.t('resume.companies.' + job.key + '.position').toString(), 14, 'normal', '#333333', 'geomanist-regular-webfont', 22);
      startY -= 1;
      printText(
        this.$i18n.t('resume.companies.' + job.key + '.company').toString()
        + ' - ' + this.$i18n.t('resume.positionType.' + job.positionType).toString(),
        9, 'normal', '#333333', 'geomanist-regular-webfont', 22
      );
      startY += 2;
      printText(this.$i18n.t('resume.companies.' + job.key + '.business').toString(), 9, 'normal', undefined, undefined, 22);
      printText(this.$i18n.t('resume.fromTo', job).toString(), 9, 'normal', undefined, undefined, 22);
      startY -= 3.6;
      printText('(' + this.getDuration(job.from, job.to) + ')', 9, 'bold', undefined, undefined, 57.5);
      printText(job.location, 9, 'normal', '#666666', undefined, 22);
      startY += 3;
      printText(this.$i18n.t('resume.technologyList').toString() + ' ' + job.technologies, 11, 'normal', undefined, undefined, 22);
      startY += 3;
      this.$i18n.t('resume.companies.' + job.key + '.tasks').toString().split('\n').forEach(task => {
        printText('\u2022 ' + task, 11, 'normal', undefined, undefined, 24);
      });
    })

    startY += 15;
    printText(this.$i18n.t('resume.education.title').toString(), 18, 'normal', undefined, 'geomanist-regular-webfont');
    printText(this.$i18n.t('resume.education.name').toString(), 11, 'normal');
    printText(this.$i18n.t('resume.education.body').toString(), 9, 'normal');
    printText('2007 - 2008', 9, 'normal', '#999999');

    startY += 12;
    printText(this.$i18n.t('resume.languages').toString(), 18, 'normal', undefined, 'geomanist-regular-webfont');
    this.languages.forEach((lang, langIdx) => {
      if (langIdx > 0) {
        startY += 2;
      }
      printText(this.$i18n.t('lang.' + lang.key).toString(), 9, 'bold');
      printText(this.$i18n.t('langLevel.' + lang.rating).toString(), 9, 'normal', '#666666');
    })

    startY += 5;
    drawLine();
    startY += 1;
    printText(this.$i18n.t('resume.generatedJsPdf').toString(), 9, 'italic', '#999999');

    doc.save('resume_danny-coulombe_' + this.$i18n.locale + '.pdf');
  }
}
