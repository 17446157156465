
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class IPhone extends Vue {
  @Prop({
    type: Boolean,
    default: true
  }) includeBorder!: boolean
}
