
import { Vue, Component, VModel } from 'vue-property-decorator'
import { links } from '@/global'
import Logo from '@/components/Logo.vue'

@Component({
  components: {
    Logo
  }
})
export default class Sidebar extends Vue {
  @VModel({ type: Boolean, default: false }) opened!: boolean

  links = links
  year = new Date().getFullYear()
}
