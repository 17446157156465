
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class CookieManager extends Vue {
  visible = false

  injectScripts() {
    const tagManager = document.createElement('script');
    tagManager.src = 'https://www.googletagmanager.com/gtag/js?id=G-61RYZTPS4G';
    tagManager.async = true;

    const script = document.createElement('script');
    script.innerHTML = `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-61RYZTPS4G');`

    document.body.append(tagManager);
    document.body.append(script);
  }

  accept() {
    localStorage.setItem('cookie_accepted', 'true');
    this.injectScripts();
    this.visible = false;
  }

  created() {
    this.visible = localStorage.getItem('cookie_accepted') !== 'true';
    if (!this.visible) {
      this.injectScripts();
    }
  }
}
