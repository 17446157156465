
import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue'
import Sticky from '@/components/Sticky.vue'

@Component({
  components: {
    Page,
    Sticky,
  }
})
export default class ErrorView extends Vue {
  code = '404'

  created() {
    this.code = this.$route.params.code;
  }
}
